(function ($) {
	var menuItems, scrollItems;

	$(document).ready(function () {


		checkMenu();

		var wrapper = $("body");
		// All list items
		menuItems = wrapper.find("a.sprung-item");

		// Anchors corresponding to menu items
		scrollItems = menuItems.map(function () {
			if($(this).attr("href") !== "#" && $($(this).attr("href")).length > 0){
				var item = $($(this).attr("href"));
				if (item.length) { return item; }
			}
		});



		// Bind click handler to menu items
		// so we can get a fancy scroll animation
		menuItems.click(function () {
			$('nav.top-bar').removeClass('expanded');
			var href = $(this).attr("href");
			var menuHeight = $('.navWrapper').outerHeight();
			var offsetTop = href === "#" ? 0 : ($(href).offset().top - menuHeight);
			$('html, body').stop().animate({ scrollTop: offsetTop }, 600, 'easeInOutCubic');
			return false;
		});
	});

	$(window).scroll(function () {
		checkMenu();
		//setActiveMenuItem();
	});

	$(window).resize(function () {
		checkMenu();
		//setActiveMenuItem();
	});

	function checkMenu() {
		var headerHeight = $(".werbung").outerHeight();
		var windowHeight = $(window).height();
		var menuHeight = 385;
		var scrollPos = $(window).scrollTop();
		if (scrollPos > headerHeight && windowHeight > menuHeight) {
			$('.navWrapper').addClass('fest');
		} else {
			$('.navWrapper').removeClass('fest');
		}
		
		if (scrollPos > headerHeight) {
			$('#back-top').fadeIn(600);
		} else {
			$('#back-top').fadeOut(600);
		}
	}
	$(document).on('click', '.closeMessage', function () {
		$('#form_message').removeClass('active')
  })

})(jQuery);



