var map;
$(document).ready(function () {

  // if ($('#kontakt').length > 0) {
  //   function initMap() {
  //
  //     var myLatLng = {lat: 51.0358899, lng: 13.7848375};
  //
  //     map = new google.maps.Map(document.getElementById('kontakt'), {
  //       center: myLatLng,
  //       zoom: 15,
  //       disableDefaultUI: true,
  //       scaleControl: false,
  //       scrollwheel: false,
  //       draggable: false,
  //       zoomControl: false,
  //       styles: [{
  //         "featureType": "administrative",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "on"}]
  //       }, {
  //         "featureType": "administrative.country",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "on"}, {"color": "#00858a"}]
  //       }, {
  //         "featureType": "administrative.country",
  //         "elementType": "labels",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "administrative.province",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "administrative.locality",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "administrative.neighborhood",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "administrative.land_parcel",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "landscape.man_made",
  //         "elementType": "geometry",
  //         "stylers": [{"color": "#f6ebcb"}]
  //       }, {
  //         "featureType": "landscape.natural",
  //         "elementType": "geometry",
  //         "stylers": [{"color": "#f7f1df"}]
  //       }, {
  //         "featureType": "landscape.natural.landcover",
  //         "elementType": "geometry.fill",
  //         "stylers": [{"color": "#f7f1df"}]
  //       }, {
  //         "featureType": "landscape.natural.terrain",
  //         "elementType": "geometry",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "landscape.natural.terrain",
  //         "elementType": "geometry.fill",
  //         "stylers": [{"color": "#f7f1df"}]
  //       }, {
  //         "featureType": "poi",
  //         "elementType": "labels",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "poi.business",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "poi.government",
  //         "elementType": "all",
  //         "stylers": [{"visibility": "on"}, {"color": "#f3dd9d"}]
  //       }, {
  //         "featureType": "poi.medical",
  //         "elementType": "geometry",
  //         "stylers": [{"color": "#fbd3da"}, {"visibility": "on"}]
  //       }, {
  //         "featureType": "poi.park",
  //         "elementType": "geometry",
  //         "stylers": [{"color": "#bde6ab"}]
  //       }, {
  //         "featureType": "road",
  //         "elementType": "geometry.stroke",
  //         "stylers": [{"visibility": "off"}]
  //       }, {"featureType": "road", "elementType": "labels", "stylers": [{"visibility": "on"}]}, {
  //         "featureType": "road",
  //         "elementType": "labels.icon",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "road.highway",
  //         "elementType": "geometry.stroke",
  //         "stylers": [{"color": "#f8a179"}]
  //       }, {
  //         "featureType": "road.highway",
  //         "elementType": "labels.icon",
  //         "stylers": [{"visibility": "off"}]
  //       }, {
  //         "featureType": "road.arterial",
  //         "elementType": "geometry.fill",
  //         "stylers": [{"color": "#ffffff"}]
  //       }, {
  //         "featureType": "road.local",
  //         "elementType": "geometry.fill",
  //         "stylers": [{"color": "#ffffff"}]
  //       }, {
  //         "featureType": "transit.station.airport",
  //         "elementType": "geometry.fill",
  //         "stylers": [{"color": "#e6dcbd"}]
  //       }, {"featureType": "water", "elementType": "geometry", "stylers": [{"color": "#008CBA"}]}]
  //     });
  //
  //     var marker = new google.maps.Marker({
  //       position: myLatLng,
  //       map: map,
  //       title: 'Pflegedienst Pharos'
  //     });
  //
  //     marker.addListener('click', function () {
  //       OpenInNewTab('https://www.google.de/maps/place/Pflege-Management+Fortune+GmbH/@51.0358487,13.7827037,17z/data=!4m13!1m7!3m6!1s0x4709c61e013e39b5:0x10ab25a9550e14bf!2sZwinglistra%C3%9Fe+47,+01277+Dresden!3b1!8m2!3d51.0358487!4d13.7848924!3m4!1s0x4171906df9b3a59d:0x849f6eda68b6d1e9!8m2!3d51.0358487!4d13.7848924');
  //     });
  //
  //
  //   }
  //
  //   function OpenInNewTab(url) {
  //     var win = window.open(url, '_blank');
  //     win.focus();
  //
  //   }
  //   initMap();
  // }
});